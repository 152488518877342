<template>
  <div>
    <base-sales :enum-interface-type-derived="EnumInterfaceTypeDerived" :sales-data="salesData" :dates.sync="dates" interface-column-name="interfaceTypeDerived" interface-selector></base-sales>
  </div>
</template>

<script>
import BaseSales from '@/layouts/BaseSales.vue';
import EnumInterfaceTypeDerived from '@/enums/EnumInterfaceTypeDerived';

export default {
  components: {
    BaseSales,
  },
  data: () => ({
    EnumInterfaceTypeDerived,
  }),
  computed: {
    salesData() {
      return this.$store.state.salesChart;
    },
    dates: {
      get() {
        return this.$store.state.dates;
      },
      set(dates) {
        this.$store.commit('setDates', dates);
      },
    },
  },
  watch: {
    dates() {
      this.$store.commit('deleteSalesChart');
      this.$store.dispatch('getSalesChart');
    },
  },
};
</script>
