<template>
  <v-select v-model="selectedInterfaces" item-text="name" :items="interfaces" label="Schnittstellen" prepend-icon="mdi-database-search" multiple small-chips hide-details single-line clearable :menu-props="{ offsetY: true }"></v-select>
</template>

<script>
import EnumInterfaceTypeDerived from '@/enums/EnumInterfaceTypeDerived';

export default {
  computed: {
    selectedInterfaces: {
      get() {
        return this.$store.state.selectedInterfaces;
      },
      set(state) {
        this.$store.commit('setSelectedInterfaces', state);
      },
    },
    interfaces() {
      return EnumInterfaceTypeDerived.filter((row) => row !== undefined).sort((a, b) => new Intl.Collator('de').compare(a.name, b.name));
    },
  },
};
</script>
