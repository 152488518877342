<script>
import { Pie, mixins } from 'vue-chartjs';
import 'chartjs-plugin-labels';

// The `import 'chartjs-plugin-labels';` registers the plugin
// globally. This renders unwanted 'NaN%' labels on all charts.
// Instead of backlisting the plugin on each chart it's
// unwanted, we simple set the fontSize to 0 as default,
// essentially abusing fontSize as whitelist.
// This is a quick fix, but works for now.
// One chart where this default fontSize is overwritten to
// someting != 0 is ../SalesPieCharts.vue
window.Chart.defaults.global.plugins.labels = {
  fontSize: 0,
};

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: {
    'chart-data': {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
  },
  methods: {
    updateChart() {
      this.$data._chart.options = this.options;
      this.$data._chart.update();
    },
  },
};
</script>
