<template>
  <bar-chart :chart-data="chartSeperated" :options="chartOptions"></bar-chart>
</template>

<script>
import cloneDeep from 'clone-deep';
import BarChart from './charts/BarChart.vue';
import DateMixin from '../mixins/DateMixin.vue';
import ValueParseMixin from '../mixins/ValueParseMixin.vue';

export default {
  components: { BarChart },
  mixins: [DateMixin, ValueParseMixin],
  props: {
    'chart-data': { type: Array, required: true },
    'y-axes-item': { type: String, required: true },
    'chart-title': { type: String },
    'interface-enum': { type: Array, required: true },
    'interface-column-name': { type: String, required: true },
    isCurrency: { type: Boolean },
  },
  computed: {
    chartOptions() {
      return {
        plugins: {
          labels: {
            render: 'value',
          },
          textOnChart: {
            text: this.$store.getters.isLoading ? 'Lade...' : 'Keine Daten zum Anzeigen',
            show: this.chartData.length === 0,
          },
        },
        tooltips: {
          mode: 'x-axis',
          callbacks: {
            label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label}: ${this.toCurrencyOrDecimal(tooltipItem.yLabel)}`,
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              type: 'time',
              offset: true,
              distribution: 'series',
              time: {
                displayFormats: {
                  day: 'DD. MMM (ddd)',
                },
                tooltipFormat: this.dateParsingString,
                displayFormat: 'DDD MM.yyyy (dddd)',
                unit: this.dateParsingUnit,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: (value) => this.toCurrencyOrDecimal(value, 0),
                beginAtZero: true,
              },
            },
          ],
        },
        maintainAspectRatio: false,
      };
    },

    chartSeperated() {
      const datasets = {};
      cloneDeep(this.chartData).forEach(({ data }) => {
        data.forEach((row) => {
          const mapped = this.interfaceEnum[row.interfaceTypeDerived ?? 100] ?? this.interfaceEnum[100];
          const arrayDirective = mapped.name;
          let match = false;

          if (datasets[arrayDirective] === undefined) {
            datasets[arrayDirective] = {
              label: arrayDirective,
              backgroundColor: mapped.color,
              data: [],
            };
          }

          datasets[arrayDirective].data.forEach((existingRow) => {
            if (existingRow.date === row.date) {
              existingRow.x = existingRow.date;
              existingRow.y = (existingRow.y ?? 0) + row[this.yAxesItem];
              match = true;
            }
          });
          if (!match) {
            row.x = row.date;
            row.y = row[this.yAxesItem];
            datasets[arrayDirective].data.push(row);
          }
        });
      });

      // chartjs wants datasets to be an array
      return { datasets: Object.values(datasets) };
    },
  },
};
</script>
