<template>
  <v-data-table :headers="tableHeaders" :loading="loading" hide-default-footer :items="tableItems" :items-per-page="tableItems.length">
    <template v-for="a in ['totalGross', 'orderValueAverage']" #[`item.${a}`]="{ item }">
      {{ toCurrencyString(parseFloat(item[a])) }}
    </template>

    <template v-for="b in tableItemTemplateArray" #[`item.${b}`]="{ item }">
      {{ parseInterfaceItem(item, b) }}
    </template>

    <template #item.date="{ item }">
      {{ parseDate(item.date) }}
    </template>

    <template #body.append="{ isMobile, items }">
      <tr v-show="items.length !== 0" :class="{ 'v-data-table__mobile-table-row': isMobile }">
        <td class="font-weight-bold" :class="{ 'v-data-table__mobile-row': isMobile }">Total</td>
        <td v-for="(header, i) in tableHeaders.slice(1)" :key="i" :class="{ 'v-data-table__mobile-row': isMobile }" class="text-end">
          <span v-if="isMobile" class="font-weight-bold">
            {{ header.text }}
          </span>
          <span class="font-weight-bold">
            {{ getTotalOfColumn(header.value, header.isCurrency) }}
          </span>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import cloneDeep from 'clone-deep';
import moment from 'moment';
import DateMixin from '../mixins/DateMixin.vue';
import ValueParseMixin from '../mixins/ValueParseMixin.vue';

export default {
  mixins: [DateMixin, ValueParseMixin],
  props: {
    'table-data': { type: Array, required: true },
    loading: { type: Boolean, required: false },
    'interface-enum': { type: Array, required: true },
    'interface-column-name': { type: String, required: true },
    'item-name': { type: String, required: true },
    isCurrency: { type: Boolean },
  },
  methods: {
    parseInterfaceItem(item, key) {
      let total = item[key];
      let percent = total / item[this.itemName];
      total = this.toCurrencyOrDecimal(total);
      percent = this.toPercentString(percent);
      return `${total} (${percent})`;
    },

    getTotalOfColumn(key, isCurrency) {
      let totalString = '';
      let totalValue = 0;

      if (key === 'orderValueAverage') {
        const totalGross = this.getRawTotalOfColumn('totalGross');
        const orderCount = this.getRawTotalOfColumn('orderCount');
        totalValue = totalGross / orderCount;
      } else {
        totalValue = this.getRawTotalOfColumn(key);
        totalString = totalValue;
      }

      if (isCurrency) totalString = this.toCurrencyString(parseFloat(totalValue));

      if (~key.indexOf(`${this.itemName}_`)) {
        let percent = totalValue / this.getRawTotalOfColumn(this.itemName);
        percent = this.toPercentString(percent);
        totalString += ` (${percent})`;
      }
      return totalString;
    },

    getRawTotalOfColumn(key) {
      let total = 0;
      this.tableItems.forEach((row) => {
        total += row[key];
      });
      return total;
    },

    parseDate(isoDateString) {
      return isoDateString === null ? '' : moment(isoDateString).format(this.dateParsingString);
    },
  },
  computed: {
    datesAreDays() {
      if (this.tableItems[0] !== undefined) {
        /**
         * Tests if iso8601 date has days
         * 2020-01-01 => true
         * 2020-01 => false
         */
        return /\d{4}-\d{2}-\d{2}/.test(this.tableItems[0].date);
      }
      return undefined;
    },

    tableHeaders() {
      return this.parsedTableData[0];
    },

    tableItemTemplateArray() {
      const returnArray = [];
      this.tableHeaders.forEach((item) => {
        if (~item.value.indexOf(`${this.itemName}_`)) {
          returnArray.push(item.value);
        }
      });
      return returnArray;
    },

    tableItems() {
      return Object.values(this.parsedTableData[1]).sort((a, b) => new Date(a.date) - new Date(b.date));
    },

    parsedTableData() {
      const perDate = {};
      const headers = [{ text: 'Datum', value: 'date' }];
      const foundInterfaces = [];

      cloneDeep(this.tableData).forEach(({ data }) => {
        data.forEach((row) => {
          if (perDate[row.date] === undefined) {
            perDate[row.date] = {
              date: row.date,
              totalGross: 0,
              orderCount: 0,
              orderValueAverage: 0,
            };
          }

          const existingRow = perDate[row.date];
          const mapped = this.interfaceEnum[row.interfaceTypeDerived ?? 100] ?? this.interfaceEnum[100];
          const interfaceName = mapped.name;

          existingRow[`${this.itemName}_${interfaceName}`] = (existingRow[`${this.itemName}_${interfaceName}`] ?? 0) + row[this.itemName];
          existingRow.totalGross += row.totalGross;
          existingRow.orderCount += row.orderCount;

          existingRow.orderValueAverage = existingRow.orderCount === 0 ? 0 : existingRow.totalGross / existingRow.orderCount;

          if (!foundInterfaces.includes(interfaceName)) {
            foundInterfaces.push(interfaceName);
            headers.push({
              text: interfaceName,
              isCurrency: true,
              value: `${this.itemName}_${interfaceName}`,
              align: 'end',
            });
          }
        });
      });

      headers.push(
        {
          text: 'Durchschnittlicher Bestellwert',
          isCurrency: true,
          value: 'orderValueAverage',
          align: 'end',
        },
        { text: 'Anzahl Bestellungen', value: 'orderCount', align: 'end' },
        { text: 'Gesamtumsatz', isCurrency: true, value: 'totalGross', align: 'end' },
      );

      return [headers, perDate];
    },
  },
};
</script>
