<template>
  <bar-chart :chart-data="chartTotal" :options="chartOptions"></bar-chart>
</template>

<script>
import cloneDeep from 'clone-deep';
import BarChart from './charts/BarChart.vue';
import DateMixin from '../mixins/DateMixin.vue';
import ValueParseMixin from '../mixins/ValueParseMixin.vue';

export default {
  components: { BarChart },
  mixins: [DateMixin, ValueParseMixin],
  props: {
    'chart-data': { type: Array, required: true },
    'x-axes-item': { type: String, required: true },
    'y-axes-item': { type: String, required: true },
    'chart-title': { type: String },
    isCurrency: { type: Boolean },
  },
  computed: {
    chartOptions() {
      return {
        plugins: {
          labels: {
            render: 'value',
          },
          textOnChart: {
            text: this.$store.getters.isLoading ? 'Lade...' : 'Keine Daten zum Anzeigen',
            show: this.chartData.length === 0,
          },
        },
        title: {
          display: this.chartTitle !== undefined,
          text: this.chartTitle,
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'x-axis',
          callbacks: {
            label: (tooltipItem) => this.toCurrencyOrDecimal(tooltipItem.yLabel),
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              type: 'time',
              offset: true,
              distribution: 'series',
              time: {
                displayFormats: {
                  day: 'DD. MMM (ddd)',
                },
                tooltipFormat: this.dateParsingString,
                displayFormat: 'DDD MM.yyyy (dddd)',
                unit: this.dateParsingUnit,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: (value) => this.toCurrencyOrDecimal(value, 0),
                beginAtZero: true,
              },
            },
          ],
        },
        maintainAspectRatio: false,
      };
    },
    chartTotal() {
      const datasets = [];
      cloneDeep(this.chartData).forEach(({ data }) => {
        data.forEach((row) => {
          let match = false;
          datasets.forEach((existingRow) => {
            if (existingRow.date === row.date) {
              existingRow.totalGross += row.totalGross;
              existingRow.orderCount += row.orderCount;
              existingRow.x = existingRow[this.xAxesItem];
              existingRow.y = existingRow[this.yAxesItem];
              match = true;
            }
          });
          if (!match) {
            row.x = row[this.xAxesItem];
            row.y = row[this.yAxesItem];
            datasets.push(row);
          }
        });
      });

      return {
        datasets: [
          {
            backgroundColor: this.$vuetify.theme.themes.light.default,
            data: datasets,
          },
        ],
      };
    },
  },
};
</script>
