<script>
export default {
  computed: {
    dateParsingString() {
      return this.datesAreDays ? 'DD. MMM YYYY (dddd)' : 'MMM YYYY';
    },
    dateParsingUnit() {
      return this.datesAreDays ? 'day' : 'month';
    },
    datesAreDays() {
      if (this.chartData[0] !== undefined) {
        /**
         * Tests if iso8601 date has days
         * 2020-01-01 => true
         * 2020-01 => false
         */
        return /\d{4}-\d{2}-\d{2}/.test(this.chartData[0].data[0].date);
      }
      return undefined;
    },
  },
};
</script>
