<template>
  <v-row>
    <v-col v-for="(pieData, i) in pieChartData" :key="i">
      <v-container class="outlined" rounded white>
        <pie-chart :chart-data="pieData" :options="pieChartOptions(pieData.title)" :width="300" align="center"></pie-chart>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import cloneDeep from 'clone-deep';
import moment from 'moment';
import PieChart from '@/components/charts/PieChart.vue';
import ValueParseMixin from '../mixins/ValueParseMixin.vue';

export default {
  components: { PieChart },
  mixins: [ValueParseMixin],
  props: {
    'chart-data': { type: Array, required: true },
    'chart-item': { type: String, required: true },
    'interface-enum': { type: Array, required: true },
    'interface-column-name': { type: String, required: true },
    isCurrency: { type: Boolean },
  },
  methods: {
    pieChartOptions(title) {
      return {
        plugins: {
          labels: {
            fontSize: 17,
            overlap: false,
            fontColor: '#ffffff',
            fontStyle: 'bold',
          },
        },
        title: {
          display: true,
          text: title,
        },
        responsive: false,
        legend: {
          position: 'bottom',
        },
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => `${data.labels[tooltipItem.index]}: ${this.toCurrencyOrDecimal(data.datasets[0].data[tooltipItem.index])}`,
          },
        },
      };
    },
    parseDate(isoDateString) {
      return isoDateString === null ? '' : moment(isoDateString).format(this.dateParsingString);
    },
  },
  computed: {
    pieChartData() {
      const vm = this;
      const perDate = {};
      cloneDeep(this.chartData).forEach(({ data }) => {
        data.forEach((row) => {
          if (perDate[row.date] === undefined) {
            perDate[row.date] = {
              foundInterfaces: [],
              date: row.date,
              title: vm.parseDate(row.date),
              datasets: [
                {
                  data: [],
                  backgroundColor: [],
                },
              ],
              labels: [],
            };
          }

          const existingRow = perDate[row.date];
          const interfaceObj = this.interfaceEnum[row.interfaceTypeDerived ?? 100] ?? this.interfaceEnum[100];
          const interfaceIndex = existingRow.foundInterfaces.indexOf(interfaceObj.name);

          if (~interfaceIndex) {
            existingRow.datasets[0].data[interfaceIndex] += row[this.chartItem];
            existingRow.labels[interfaceIndex] = interfaceObj.name;
          } else {
            existingRow.datasets[0].data.push(row[this.chartItem]);
            existingRow.datasets[0].backgroundColor.push(interfaceObj.color);
            existingRow.labels.push(interfaceObj.name);
            existingRow.foundInterfaces.push(interfaceObj.name);
          }
        });
      });

      let datasets = Object.values(perDate);
      datasets = datasets.filter((chart) => chart.datasets[0].data.reduce((a, b) => a + b) > 0);

      datasets.forEach((arr, index) => {
        arr.labels.forEach((value, i) => {
          datasets[index].labels[i] +=
            // eslint-disable-next-line prefer-template
            ' ' + this.toCurrencyOrDecimal(datasets[index].datasets[0].data[i]);
        });
      });
      return datasets.sort((a, b) => new Date(a.date) - new Date(b.date));
    },

    dateParsingString() {
      return this.datesAreDays ? 'DD. MMM YYYY (dddd)' : 'MMM YYYY';
    },
    dateParsingUnit() {
      return this.datesAreDays ? 'day' : 'month';
    },
    datesAreDays() {
      if (this.chartData[0] !== undefined) {
        /**
         * Tests if iso8601 date has days
         * 2020-01-01 => true
         * 2020-01 => false
         */
        return /\d{4}-\d{2}-\d{2}/.test(this.chartData[0].data[0].date);
      }
      return undefined;
    },
  },
};
</script>
