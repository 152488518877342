<script>
import { Bar, mixins } from 'vue-chartjs';
import '@/helper/chartJsTextOnChart';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    'chart-data': {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
  },
  methods: {
    updateChart() {
      this.$data._chart.options = this.options;
      this.$data._chart.update();
    },
  },
};
</script>
