// heavily customized https://github.com/NeverBounce/chartjs-plugin-empty-overlay/blob/master/src/plugin.js
(() => {
  const Chart = (window as any).Chart as any;
  const { helpers } = Chart;

  Chart.defaults.global.plugins.textOnChart = {
    text: 'Keine Daten',
    show: false,
    fontColor: '#000000',
    fillStyle: 'rgba(255,255,255,0.2)',
    fontSize: 'auto',
  };

  Chart.TextOnChart = Chart.Element.extend({
    position: 'chartArea',

    initialize(config: any) {
      helpers.extend(this, config);
    },

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    update() {},

    isHorizontal() {
      return this.options.position === 'top' || this.options.position === 'bottom';
    },

    draw() {
      const { ctx } = this;
      const globalDefault = Chart.defaults.global;
      const itemOrDefault = helpers.getValueOrDefault;
      const pluginOpts = this.options;
      const { chartArea } = this.chartInstance;

      const x = chartArea.left;
      const y = chartArea.top;
      const width = chartArea.right - chartArea.left;
      const height = chartArea.bottom - chartArea.top;
      const textX = x / 2 + width / 2;
      const textY = y + height / 2;

      const { text } = pluginOpts;
      const fontSizeOpt = itemOrDefault(pluginOpts.fontSize, globalDefault.defaultFontSize);
      const fontSize = fontSizeOpt === 'auto' ? Math.sqrt(width) : fontSizeOpt;
      const fontStyle = itemOrDefault(pluginOpts.fontStyle, globalDefault.defaultFontStyle);
      const fontFamily = itemOrDefault(pluginOpts.fontFamily, globalDefault.defaultFontFamily);
      ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, textX, textY, width);
    },
  });

  function createOverlay(chartInstance: any, textOnChartOpts: any) {
    const textOnChart = new Chart.TextOnChart({
      ctx: chartInstance.chart.ctx,
      options: textOnChartOpts,
      chartInstance,
    });
    chartInstance.textOnChart = textOnChart;
  }

  Chart.plugins.register({
    beforeUpdate(chartInstance: any) {
      const pluginOpts = helpers.configMerge(Chart.defaults.global.plugins.textOnChart, chartInstance.options.plugins.textOnChart);

      if (pluginOpts.text?.length > 0 && pluginOpts.show) {
        if (chartInstance.textOnChartBox !== pluginOpts.text) {
          Chart.layoutService.removeBox(chartInstance, chartInstance.textOnChart);
          chartInstance.textOnChartBox = false;
        }
        if (!chartInstance.textOnChartBox) {
          chartInstance.textOnChartBox = pluginOpts.text;
          createOverlay(chartInstance, pluginOpts);
          Chart.layoutService.addBox(chartInstance, chartInstance.textOnChart);
        }
      } else if (chartInstance.textOnChartBox) {
        delete chartInstance.textOnChartBox;
        Chart.layoutService.removeBox(chartInstance, chartInstance.textOnChart);
      }
    },
  });
})();
