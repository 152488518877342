<template>
  <div>
    <the-option-bar :metricItem.sync="itemKey" :dates.sync="dates" @update:dates="$emit('update:dates', $event)"></the-option-bar>

    <base-divider></base-divider>

    <v-container class="white" rounded fluid>
      <sales-chart-total :chart-data="salesDataFilteredByStores" x-axes-item="date" :y-axes-item="itemKey.item" :isCurrency="itemKey.isCurrency" chart-title="Gesamtumsatz"></sales-chart-total>
    </v-container>

    <base-divider></base-divider>

    <v-container class="white" rounded fluid>
      <sales-chart-seperated :chart-data="salesDataFilteredByStores" :y-axes-item="itemKey.item" :isCurrency="itemKey.isCurrency" :interface-column-name="interfaceColumnName" :interface-enum="enumInterfaceTypeDerived"></sales-chart-seperated>
    </v-container>

    <base-divider></base-divider>

    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-table</v-icon>
            Detaillierte Tabelle
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <sales-table :table-data="salesDataFilteredByStores" :interface-column-name="interfaceColumnName" :interface-enum="enumInterfaceTypeDerived" :item-name="itemKey.item" :isCurrency="itemKey.isCurrency"></sales-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <base-divider></base-divider>

    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-chart-pie</v-icon>
            Kuchendiagramme
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <the-interface-selector v-if="interfaceSelector"></the-interface-selector>

          <sales-pie-charts :chart-data="salesDataFilteredByStoresAndInterfaces" :chart-item="itemKey.item" :isCurrency="itemKey.isCurrency" :interface-column-name="interfaceColumnName" :interface-enum="enumInterfaceTypeDerived"></sales-pie-charts>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import cloneDeep from 'clone-deep';
import BaseDivider from '@/components/BaseDivider.vue';
import TheOptionBar from '@/components/TheOptionBar.vue';
import TheInterfaceSelector from '@/components/TheInterfaceSelector.vue';
import SalesChartTotal from '@/components/SalesChartTotal.vue';
import SalesChartSeperated from '@/components/SalesChartSeperated.vue';
import SalesTable from '@/components/SalesTable.vue';
import SalesPieCharts from '@/components/SalesPieCharts.vue';

export default {
  props: {
    'enum-interface-type-derived': {
      type: Array,
      required: true,
    },
    'sales-data': {
      type: Object,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
    'interface-selector': {
      type: Boolean,
    },
    'interface-column-name': {
      type: String,
      required: true,
    },
  },
  components: {
    BaseDivider,
    TheOptionBar,
    TheInterfaceSelector,
    SalesChartTotal,
    SalesChartSeperated,
    SalesTable,
    SalesPieCharts,
  },
  data: () => ({
    itemKey: {},
  }),
  computed: {
    selectedStores() {
      return this.$store.state.selectedStores;
    },
    salesDataResponse() {
      return Object.values(cloneDeep(this.salesData));
    },
    salesDataFilteredByStores() {
      if (this.selectedStores.length === 0) {
        return this.salesDataResponse;
      }
      return this.salesDataResponse.filter((filter) => this.selectedStores.includes(filter.label));
    },
    selectedInterfaces() {
      return this.interfaceSelector ? this.$store.state.selectedInterfaces : [];
    },
    salesDataFilteredByStoresAndInterfaces() {
      if (this.selectedInterfaces.length === 0) {
        return this.salesDataFilteredByStores;
      }
      const returnArray = [];
      this.salesDataFilteredByStores.forEach((store) => {
        returnArray.push({
          label: store.label,
          data: store.data.filter((row) => this.selectedInterfaces.includes((this.enumInterfaceTypeDerived[row.interfaceTypeDerived] ?? this.enumInterfaceTypeDerived[100]).name)),
        });
      });
      return returnArray;
    },
  },
};
</script>
